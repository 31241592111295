import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import clsx from "clsx";
import {
  PreventFlashOnWrongTheme,
  Theme,
  ThemeProvider,
  useTheme,
} from "remix-themes";

import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useLoaderData,
  useLocation,
  useRouteError,
  useRouteLoaderData,
} from "@remix-run/react";
import i18nServer, { localeCookie } from "./modules/i18n.server";
import { useChangeLanguage } from "remix-i18next/react";
// eslint-disable-next-line import/no-named-as-default
import posthog from "posthog-js";

export const handle = { i18n: ["translation"] };

import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";

import { themeSessionResolver } from "./sessions.server";

import stylesheet from "~/tailwind.css?url";

import "@fontsource-variable/nunito/wght.css";
import "@fontsource/pt-sans/400.css";
import { useEffect } from "react";
import { Button } from "./components/ui/button";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: stylesheet },
];

export async function loader({ request }: LoaderFunctionArgs) {
  // fetch data
  const { getTheme } = await themeSessionResolver(request);
  const locale = await i18nServer.getLocale(request);

  return json(
    {
      theme: getTheme(),
      locale,
    },

    { headers: { "Set-Cookie": await localeCookie.serialize(locale) } },
  );
}

export function LayoutContent({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const data = useRouteLoaderData<typeof loader>("root");

  const [theme] = useTheme();
  return (
    <html
      lang={data?.locale ?? "en"}
      className={clsx(theme, "h-full scroll-smooth")}
    >
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        {process.env.NODE_ENV !== "development" && (
          <script
            defer
            data-domain="remarc.ai"
            data-api="https://api30.remarc.ai/api/event"
            src="https://api30.remarc.ai/js/script.js"
          />
        )}

        <Meta />
        <PreventFlashOnWrongTheme ssrTheme={Boolean(data?.theme ?? "ligth2")} />
        <Links />
      </head>
      <body className="bg-[#f4f2ee]">
        {process.env.NODE_ENV === "development" && (
          <div className="fixed left-0 top-0 z-50 flex w-[30px] items-center justify-center bg-gray-200 py-[2.5px] text-[12px] uppercase text-black sm:bg-red-200 md:bg-yellow-200 lg:bg-green-200 xl:bg-blue-200 2xl:bg-pink-200">
            <span className="block sm:hidden">all</span>
            <span className="hidden sm:block md:hidden">sm</span>
            <span className="hidden md:block lg:hidden">md</span>
            <span className="hidden lg:block xl:hidden">lg</span>
            <span className="hidden xl:block 2xl:hidden">xl</span>
            <span className="hidden 2xl:block">2xl</span>
          </div>
        )}
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function Layout({ children }: Readonly<{ children: React.ReactNode }>) {
  const data = useRouteLoaderData<typeof loader>("root");
  return (
    <ThemeProvider
      specifiedTheme={data?.theme ?? Theme.LIGHT}
      themeAction="/action/set-theme"
    >
      <LayoutContent>{children}</LayoutContent>
    </ThemeProvider>
  );
}

function App() {
  const { locale } = useLoaderData<typeof loader>();
  useChangeLanguage(locale);
  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    posthog.capture("$pageview");
  }, [location]);
  return <Outlet />;
}

export function ErrorBoundary() {
  const error = useRouteError() as Error | undefined;

  if (isRouteErrorResponse(error)) {
    return (
      <LayoutContent>
        <section className="section mt-20">
          <div className="container relative z-30">
            <h1 className="mb-4 text-center text-4xl">{error.statusText}</h1>
            <div className="row items-center">
              <div data-aos="fade-in" className="col-12 text-center">
                <img
                  src="/images/404.png"
                  alt="404 illustration"
                  width={750}
                  height={438}
                  className="mx-auto mb-14 block w-full max-w-[750px] select-none"
                />
                <div className="content mb-5 text-lg">
                  <p>{error.data}</p>
                </div>
                <Button href="/">Go back home</Button>
              </div>
            </div>
          </div>
        </section>
      </LayoutContent>
    );
  }

  captureRemixErrorBoundaryError(error);

  return (
    <>
      <h1>Error!</h1>
      <p>{error?.message ?? "Unknown error"}</p>
    </>
  );
}

export default withSentry(App);
